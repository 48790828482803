(function () {
  'use strict';

  angular
    .module('wbFaq')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      faq: 'Frequenty asked questions',
      'faq.shortLabel': 'FAQ'
    });
  }
}());
